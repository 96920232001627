let recognition;
let isListening = false;

// Utility function for delay
export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const startListening = (onCommandDetected) => {
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

  if (!SpeechRecognition) {
    console.error('Speech Recognition API not supported in this browser.');
    return;
  }

  if (!isListening) {
    recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    recognition.onresult = (event) => {
      const transcript = event.results[event.resultIndex][0].transcript.toLowerCase();
      console.log(`Recognized command: ${transcript}`);

      // Match commands and trigger callbacks if provided
      const commands = {
        "about": () => onCommandDetected("about"),
        "projects": () => onCommandDetected("projects"),
        "skills": () => onCommandDetected("skills"),
        "blog": () => onCommandDetected("blog"),
        "contact": () => onCommandDetected("contact"),
        "certificates": () => onCommandDetected("certificates"),
        "resume": () => onCommandDetected("resume"),
        "switch": () => onCommandDetected("switch")
      };

      for (let [command, action] of Object.entries(commands)) {
        if (transcript.includes(command)) {
          console.log(`Detected command: ${command}`);
          action();
          break;
        }
      }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      if (event.error === 'aborted' || event.error === 'network') {
        console.log("Restarting recognition...");
        restartListening(onCommandDetected); // Restart if necessary
      }
    };

    recognition.onend = () => {
      console.log("Recognition ended, restarting...");
      restartListening(onCommandDetected); // Keep recognition continuous
    };

    recognition.start();
    isListening = true;
    console.log("Started listening for commands...");
  }
};

export const stopListening = () => {
  if (recognition) {
    recognition.stop();
    isListening = false;
    console.log("Stopped listening for commands.");
  }
};

const restartListening = (onCommandDetected) => {
  stopListening();
  delay(500).then(() => startListening(onCommandDetected)); // Short delay to restart
};
