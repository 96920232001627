import React from 'react';
import './AssistantModal.css';
import cocoImage from '../images/coco.png';
import kikiImage from '../images/kiki.png';

const AssistantModal = ({ isOpen, onClose, onSelect }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Choose Your Assistant</h2>
        <div className="assistant-images">
          <img src={cocoImage} alt="Coco" className="assistant-image" onClick={() => onSelect('coco')} />
          <img src={kikiImage} alt="Kiki" className="assistant-image" onClick={() => onSelect('kiki')} />
        </div>
        <div className="assistant-options">
          <button onClick={() => { onSelect('coco'); onClose(); }}>Coco</button>
          <button onClick={() => { onSelect('kiki'); onClose(); }}>Kiki</button>
        </div>
      </div>
    </div>
  );
};

export default AssistantModal;
