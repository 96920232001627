import React, { useEffect, useRef, useState } from 'react';
import ContactForm from './ContactForm';
import './code-veryharsh.css';

function CodeVeryHarsh() {
  const [activeProject, setActiveProject] = useState(0);
  const sectionsRef = useRef([]);
  const projectsContainerRef = useRef(null);

  const projects = ['Portfolio Website', 'To-Do List App']; // Define your projects here

  const handleScroll = (direction) => {
    setActiveProject((prev) => {
      let newProject = direction === 'next' ? prev + 1 : prev - 1;
      // Prevent scrolling past the available projects
      if (newProject < 0) newProject = projects.length - 1; // Loop to last project
      if (newProject >= projects.length) newProject = 0; // Loop to first project
      return newProject;
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="portfolio-container">
      <section id="about" className="section" ref={(el) => (sectionsRef.current[0] = el)}>
        <h2>About Me</h2>
        <p>
          Hi! I'm a passionate developer with experience in building web applications using modern technologies.
          My goal is to create user-friendly, efficient solutions that solve real-world problems.
        </p>
      </section>

      <div className="portfolio-container">
        <section id="projects" className="section projects-section" ref={(el) => (sectionsRef.current[1] = el)}>
          <h2>Projects</h2>
          <div className="projects-container" ref={projectsContainerRef} style={{ transform: `translateX(-${activeProject * 280}px)` }}>
            {projects.map((project, index) => (
              <div
                key={index}
                className={`project ${activeProject === index ? 'centered' : ''}`}
              >
                <h3>{`${index + 1}. ${project}`}</h3>
                <p>A simple project built using React and CSS for navigation.</p>
                <a href="#">{`View ${project}`}</a>
              </div>
            ))}
          </div>
          <div className="navigation">
            <button className="prev-button" onClick={() => handleScroll('prev')}>Prev</button>
            <button className="next-button" onClick={() => handleScroll('next')}>Next</button>
          </div>
        </section>
      </div>

      <section id="skills" className="section" ref={(el) => (sectionsRef.current[2] = el)}>
        <h2>Skills</h2>
        <p>JavaScript, React, CSS, HTML, Node.js, and more.</p>
      </section>

      <section id="blog" className="section" ref={(el) => (sectionsRef.current[3] = el)}>
        <h2>Blog</h2>
        <p>Insights and articles about coding, technology, and best practices.</p>
        <a href="#blog">Read My Blog</a>
      </section>

      <section id="certifications" className="section" ref={(el) => (sectionsRef.current[4] = el)}>
        <h2>Certifications & Participation</h2>
        <p>Details on my completed certifications and participations in coding challenges.</p>
      </section>

      <section id="contact" className="section" ref={(el) => (sectionsRef.current[5] = el)}>
        <h2>Contact</h2>
        <ContactForm />
      </section>

      <section id="resume" className="section" ref={(el) => (sectionsRef.current[6] = el)}>
        <h2>Resume</h2>
        <p>Download my resume <a href="/path/to/resume.pdf" download>here</a>.</p>
      </section>
    </div>
  );
}

export default CodeVeryHarsh;
