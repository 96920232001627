import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CodeVeryHarsh from './components/code-veryharsh';
import PhotoVeryHarsh from './components/photo-veryharsh';
import AssistantModal from './components/AssistantModal';
import SwitchButton from './components/SwitchButton';
import { startListening, stopListening, delay } from './speechRecognition';

import './App.css';

function App() {
  const [isModalOpen, setModalOpen] = useState(true);
  const [assistant, setAssistant] = useState(null);
  const [isCodePortfolio, setIsCodePortfolio] = useState(true);

  const handleSelectAssistant = (selectedAssistant) => {
    setAssistant(selectedAssistant);
  };

  const switchPortfolio = () => {
    setIsCodePortfolio((prev) => !prev);
  };

  const handleVoiceCommand = (command) => {
    console.log(`Voice command detected: ${command}`);
    if (command.includes("about")) document.getElementById("about")?.scrollIntoView({ behavior: "smooth" });
    else if (command.includes("projects")) document.getElementById("projects")?.scrollIntoView({ behavior: "smooth" });
    else if (command.includes("skills")) document.getElementById("skills")?.scrollIntoView({ behavior: "smooth" });
    else if (command.includes("blog")) document.getElementById("blog")?.scrollIntoView({ behavior: "smooth" });
    else if (command.includes("contact")) document.getElementById("contact")?.scrollIntoView({ behavior: "smooth" });
    else if (command.includes("certificates")) document.getElementById("certifications")?.scrollIntoView({ behavior: "smooth" });
    else if (command.includes("resume")) document.getElementById("resume")?.scrollIntoView({ behavior: "smooth" });
    else if (command.includes("switch")) {
      console.log("Switching portfolio...");
      switchPortfolio();
    }
  };

  useEffect(() => {
    if (assistant) {
      startListening(handleVoiceCommand);
    } else {
      stopListening();
    }

    return () => stopListening(); // Cleanup on unmount
  }, [assistant]);

  return (
    <Router>
      <div className="app">
        <header className="app-header">
          <div className="logo">
            <span className="very">very</span>
            <span className="harsh">Harsh</span>
            <span className="dev">.dev</span>
          </div>
          <nav className="header-tabs">
            <a href="#about">About</a>
            <a href="#projects">Projects</a>
            <a href="#skills">Skills</a>
            <a href="#blog">Blog</a>
            <a href="#contact">Contact</a>
          </nav>
        </header>

        <AssistantModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSelect={handleSelectAssistant}
        />

        {/* Conditionally render portfolio based on isCodePortfolio */}
        {isCodePortfolio ? <CodeVeryHarsh /> : <PhotoVeryHarsh />}

        <SwitchButton onClick={switchPortfolio} />

        <footer className="footer">
          <span>&copy; 2024 veryharsh.dev</span>
        </footer>
      </div>
    </Router>
  );
}

export default App;
