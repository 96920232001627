import React from 'react';
import './SwitchButton.css';
import cameraIcon from '../images/camera.png'; // Adjusted import path

function SwitchButton({ onClick }) {
  return (
    <div className="switch-button" onClick={onClick}>
      <img src={cameraIcon} alt="Switch Icon" className="switch-icon" />
    </div>
  );
}

export default SwitchButton;
