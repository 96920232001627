import React from 'react';

function PhotoVeryHarsh() {
  return (
    <div className="photography-portfolio">
      <h2>Photography Work</h2>
      <p>Welcome to my photography portfolio! Here are some of my favorite photos:</p>

      <div className="photo">
        <img src="link-to-your-photo1.jpg" alt="Description of Photo 1" />
        <p>Description of Photo 1</p>
      </div>

      <div className="photo">
        <img src="link-to-your-photo2.jpg" alt="Description of Photo 2" />
        <p>Description of Photo 2</p>
      </div>

      {/* Add more photos as needed */}

      <div className="about">
        <h2>About Me</h2>
        <p>Write a brief description about yourself and your passion for photography.</p>
      </div>
    </div>
  );
}

export default PhotoVeryHarsh;
